<template>
  <Loading v-if="isLoading"></Loading>
  <main v-else>
    <h2 class="text-2xl font-bold font-blue-400 page-title">
      Notification Portal
    </h2>
    <div class="flex justify-between items-center mt-14">
      <div class=" w-96">
        <input
          type="text"
          class="py-3 pl-6 border-2 rounded-md border-gray-200 w-full"
          placeholder="Search"
          v-model="search"
        />
      </div>
      <div>
        <button
          @click="$router.push({ name: 'CreateNotifications' })"
          class="w-80  bg-blue-600 py-3 text-white rounded-md"
        >
          Create a new notification
        </button>
      </div>
    </div>

    <div class="overflow-scroll pb-6 mt-20">
      <table class="shadow-lg bg-white w-full">
        <thead>
          <tr>
            <th
              class="text-l p-3 text-left"
              colspan="13"
              style="background: #DEE6F2;"
            >
              Notification Details
            </th>
          </tr>
          <tr>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Title
            </th>
            <th
              class="bg-blue-100  text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Date
            </th>
            <th
              class="bg-blue-100  text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Time
            </th>
            <th
              class="bg-blue-100  text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Published Status
            </th>
          </tr>
        </thead>
        <tbody v-if="formatNotifications && formatNotifications.length">
          <tr
            v-for="(item, i) in formatNotifications"
            :key="i"
            class="hover:bg-gray-300"
          >
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ item.data.title }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ moment(item.dateCreated).format("DD MMM YYYY ") || "N/A" }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ moment(item.dateCreated).format(" h:mm:ss a") || "N/A" }}
            </td>
            <td>
              <p
                @click="
                  $router.push({
                    name: 'PreviewNotifications',
                    params: {
                      id: item._id,
                    },
                  })
                "
                class="text-blue-500 ml-10 cursor-pointer"
              >
                View
              </p>
            </td>
          </tr>
        </tbody>
        <div v-else>
          <div class="py-3 pl-3 text-sm">
            There are no notifications
          </div>
        </div>
      </table>
    </div>
  </main>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import Loading from "./general-components/Loading.vue";
export default {
  data() {
    return {
      notifications: [],
      moment,
      search: "",
      isLoading: true,
    };
  },
  components: {
    Loading,
  },
  computed: {
    ...mapGetters(["GET_NOTIFICATIONS"]),
    formatNotifications() {
      return this.GET_NOTIFICATIONS.data.filter((notification) =>
        notification.data.title
          .toLowerCase()
          .includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    ...mapActions(["GET_ALL_NOTIFICATIONS"]),
    async fetchAllNotifications() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("GET_ALL_NOTIFICATIONS");
        this.isLoading = false;
      } catch (error) {}
    },
  },
  async created() {
    this.fetchAllNotifications();
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
